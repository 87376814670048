import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";


const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#024670',
        main: '#00263D',
        dark: '#171927'
      },
      action: {
        active: '#0278bf',
      }
    },
});
  
export default theme;
